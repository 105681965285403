import React from 'react';
import DotChart from './Homepage/chart';

function Survey() {
  return (
    <div>
      
      <h2>Comming soon...</h2>
        
    </div>
  );
}

export default Survey;
