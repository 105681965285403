import React from 'react';

function Cantonese() {
  return (
    <div>
      <h2 className='ms-3 pt-3'>Comming soon...</h2>
    </div>
  );
}

export default Cantonese;